:root {
  --font-heading-regular: televoice-regular, sans-serif;
  --font-heading-semibold: televoice-semibold, sans-serif;
  --font-body: opensans, helvetica, arial, sans-serif;
}

html[lang="zh"] {
  --font-body:
    notosanssc,
    helvetica,
    arial,
    "Microsoft YaHei New",
    "Microsoft Yahei",
    "微软雅黑",
    宋体,
    simsun,
    stxihei,
    "华文细黑",
    sans-serif;
  --font-heading-regular: var(--font-body);
  --font-heading-semibold: var(--font-body);
}

html[lang="ja"] {
  --font-body: notosansjp, meiryo, helvetica, arial, sans-serif;
  --font-heading-regular: var(--font-body);
  --font-heading-semibold: var(--font-body);
}

body {
  font-family: var(--font-body);
  font-size: 1.125rem; /* 18px */
  font-weight: 400;
  color: var(--foreground-2);
}

strong {
  font-weight: 700;
  color: var(--black);
}

blockquote {
  font-family: var(--font-heading-semibold);
  font-size: pxToRem(30);
}

label,
.label {
  font-style: normal;
  font-weight: 600;
  font-size: pxToRem(10);
  line-height: pxToRem(14);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.label {
  font-family: var(--font-body);
}

@font-face {
  font-family: Televoice-Regular;
  src:
    url("../fonts/TeleVoice-Regular.woff2"),
    url("../fonts/TeleVoice-Regular.woff"),
    url("../fonts/TeleVoice-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: Televoice-Semibold;
  src:
    url("../fonts/TeleVoice-Semibold.woff2"),
    url("../fonts/TeleVoice-Semibold.woff"),
    url("../fonts/TeleVoice-Semibold.otf");
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src:
    url("../fonts/OpenSans-Regular.woff2"),
    url("../fonts/OpenSans-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src:
    url("../fonts/OpenSans-SemiBold.woff2"),
    url("../fonts/OpenSans-SemiBold.woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Bold.woff2"), url("../fonts/OpenSans-Bold.woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: NotoSansSC;
  src:
    url("../fonts/NotoSansSC-Regular.woff2"),
    url("../fonts/NotoSansSC-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: NotoSansSC;
  src:
    url("../fonts/NotoSansSC-Medium.woff2"),
    url("../fonts/NotoSansSC-Medium.woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: NotoSansSC;
  src:
    url("../fonts/NotoSansSC-Bold.woff2"),
    url("../fonts/NotoSansSC-Bold.woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: NotoSansJP;
  src:
    url("../fonts/NotoSansJP-Regular.woff2"),
    url("../fonts/NotoSansJP-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: NotoSansJP;
  src:
    url("../fonts/NotoSansJP-Medium.woff2"),
    url("../fonts/NotoSansJP-Medium.woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: NotoSansJP;
  src:
    url("../fonts/NotoSansJP-Bold.woff2"),
    url("../fonts/NotoSansJP-Bold.woff");
  font-weight: 700;
  font-display: swap;
}
