@import "./tools";
@import "./mediaqueries";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: var(--font-heading-semibold);
  font-weight: 400;
  margin-block-start: pxToRem(40);
  font-size: pxToRem(44);

  @include laptop {
    font-size: pxToRem(60);
  }
}

h2 {
  font-family: var(--font-heading-regular);
  font-size: pxToRem(32);
  font-weight: normal;
  margin-block-start: pxToRem(56);
}

h3 {
  font-family: var(--font-body);
  font-size: pxToRem(20);
  font-weight: 700;
  margin-block-start: pxToRem(33);
}

p {
  font-family: var(--font-body);
  margin: pxToRem(13) 0 0 0;
  line-height: 160%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-end: 0;
  word-break: break-word;

  &:first-child {
    margin-block-start: 0;
  }
}

h1,
h2,
h3 {
  color: var(--black);
}

hr {
  border: 0;
  background: var(--background-2);
  height: 2px;
  margin: var(--space-3) 0;
}
