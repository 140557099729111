:root {
  // Space
  --space: 0.5rem;
  @for $i from 1 through 12 {
    --space-#{$i}: calc(var(--space) * #{$i});
  }

  // Radius
  $radii: 0.25rem, 0.5rem, 1rem, 2rem, 4rem, 8rem, 16rem, 32rem;
  $index: 1;
  @each $value in $radii {
    --radius-#{$index}: #{$value};
    $index: $index + 1;
  }
}
