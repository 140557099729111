/* stylelint-disable */
@import "./reset";
@import "./colors";
@import "./root-utils";
@import "./typography-editorial";
@import "./typography-display";
@import "./typography";
@import "swiper/swiper-bundle.css";

:root {
  --header-height: 4rem;
  --breadcrumbs-height: 2.5rem;
  --border: 1px solid var(--stroke-1);
  --border-no-opacity: 1px solid #e3eaf1;
  --mobile-border: 1px solid var(--stroke-1);
  --anchormenu-height: #{pxToRem(48)};
  --featured-tabs-height: #{pxToRem(45)};
  --z-index-global-alerts: 80;
  --z-index-modal: 60;
  --z-index-header: 50;
  --z-index-header-sub-menu: 40;
  --z-index-header-sub-menu-overlay: 35;
  --z-index-anchor-menu: 30;
  --width-narrow: 720px;
  --width-medium: 900px;
  --width-wide: 1200px;
  --width-x-wide: 1400px;
  --width-xx-wide: 1600px;

  @include laptop {
    --featured-tabs-height: #{pxToRem(60)};
  }

  @include not-touchscreen {
    @include laptop-large {
      --header-height: 5rem;
    }

    @include desktop {
      --anchormenu-height: #{pxToRem(52)};
      --header-height: 5rem;
    }
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding: calc(var(--anchormenu-height));
}

.visuallyHidden {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  left: 0;
}

body {
  overflow-x: hidden;
  max-width: 100vw;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

//_______________________________________________________________________
.swiper-button-prev,
.swiper-button-next {
  color: var(--white) !important;

  &:after {
    font-size: pxToRem(32) !important;

    @include desktop {
      font-size: pxToRem(40) !important;
    }
  }
}

.swiper-pagination {
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: pxToRem(24) !important;
  width: 100% !important;
  max-width: var(--width-x-wide) !important;
  padding: 0 pxToRem(24);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  gap: pxToRem(8);

  @include xxl {
    padding: 0;
    bottom: pxToRem(48) !important;
    gap: pxToRem(16);
  }

  .swiper-pagination-bullet {
    width: auto !important;
    height: auto !important;
    max-width: pxToRem(320) !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    gap: pxToRem(8);
    flex: 1;
    border-radius: 0 !important;
    background: transparent !important;
    position: relative;
    color: var(--white);
    opacity: 0.33 !important;
    text-align: left;
    font-size: pxToRem(12);
    line-height: 1.2;
    font-weight: 600;
    transition: all 0.3s linear;
    justify-content: space-evenly;

    &:hover {
      opacity: 0.5 !important;
    }

    .progress {
      height: pxToRem(8);
      width: 100%;
      background-color: var(--white);
      border-radius: 4px;

      @include desktop {
        height: pxToRem(4);
      }
    }

    .copy {
      position: relative;
      display: none !important;

      @include desktop {
        display: flex !important;
      }
    }

    &.paginationEllipsis {
      width: pxToRem(12) !important;
      height: pxToRem(12) !important;
      max-width: pxToRem(12) !important;
      border-radius: 50% !important;
      background-color: var(--white) !important;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;

    &:hover {
      opacity: 1 !important;
    }
  }

  .progressWrapper {
    width: 100%;
    height: pxToRem(8);
    background-color: var(--foreground-i3);
    border-radius: pxToRem(4);

    @include desktop {
      height: pxToRem(4);
    }
  }
}
