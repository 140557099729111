:root {
  /* Primary */
  --white: #fff;
  --black: #000;
  --signature: #0e103e;

  --signature-lighter-1: #121544;
  --signature-lighter-2: #161b49;
  --signature-lighter-3: #1a204f;
  --signature-lighter-4: #1f2555;
  --signature-lighter-5: #232a5a;
  --signature-lighter-6: #282f60;
  --signature-lighter-7: #2d3566;
  --signature-lighter-8: #323a6c;
  --signature-lighter-9: #374072;
  --signature-lighter-10: #3c4578;
  --signature-darker-1: #0e103e;
  --signature-darker-2: #12162d;
  --signature-darker-3: #141724;
  --signature-darker-4: #17181b;
  --signature-darker-5: #181818;
  --tobii-signature-blue: #0e103e;
  --tobii-ocean-blue: #0071eb;
  --tobii-frost-blue: #f2f8ff;
  --tobii-peach: #fd6f60;
  --tobii-violet: #8018d8;
  --tobii-violet-lighter-1: color-mix(in srgb, var(--tobii-violet) 90%, white);
  --tobii-violet-lighter-2: color-mix(in srgb, var(--tobii-violet) 80%, white);
  --tobii-violet-lighter-3: color-mix(in srgb, var(--tobii-violet) 70%, white);
  --tobii-violet-darker-1: color-mix(in srgb, var(--tobii-violet) 90%, black);
  --tobii-violet-darker-2: color-mix(in srgb, var(--tobii-violet) 80%, black);
  --tobii-violet-darker-3: color-mix(in srgb, var(--tobii-violet) 70%, black);
  --tobii-ocean-blue-80b: #00172f;
  --tobii-ocean-blue-60b: #002d5e;
  --tobii-ocean-blue-30b: #004fa5;
  --tobii-ocean-blue-30w: #4d9cf1;
  --tobii-ocean-blue-60w: #99c6f7;
  --tobii-light-gray: #e8e8e8;

  /* Secondary */
  --medium: #005b94;
  --light: #90b6ff;

  /* Additional */
  --green: #44a634;
  --orange: #cf6925;
  --red: #c72924;

  /* CTA */
  --cta: var(--medium);

  /* Foreground */
  --foreground-1: var(--black);
  --foreground-2: rgb(45 55 62 / 76%);
  --foreground-3: rgb(56 61 86 / 40%);
  --foreground-i1: var(--white);
  --foreground-i2: rgb(255 255 255 / 70%);
  --foreground-i3: rgb(255 255 255 / 30%);
  --foreground-disabled: rgb(56 61 86 / 40%);
  --foreground-idisabled: rgb(255 255 255 / 30%);

  /* Background */
  --background-1: var(--white);
  --background-2: #ededed;
  --background-3: #fafafa;
  --background-4: #f4f4f4;
  --background-5: #e6e6e6;
  --background-6: #e0e0e0;
  --background-7: #d9d9d9;
  --background-i1: var(--signature);
  --background-i2: var(--black);

  /* Strokes */
  --stroke-1: rgb(114 152 187 / 20%);
  --stroke-i1: rgb(200 207 240 / 15%);
  --stroke-2: rgb(175 181 201 / 55%);

  /* Feedback */
  --feedback-success: var(--green);
  --feedback-warning: var(--orange);
  --feedback-danger: var(--red);

  /* Other */
  --header-background: rgb(0 0 0 / 30%);
  --header-background-no-blur: rgb(0 0 0 / 40%);
  --breadcrumb-background: rgb(215 215 215 / 50%);
  --breadcrumb-background-inverted: rgb(14 16 62 /40%);
  --overlay: #182654;
  --table-row-gray: #f4f4f5;
  --modal-boxshadow: 0 6px 13px rgb(0 0 0 / 10%);
  --active-gradient: radial-gradient(
    50% 75.3% at 50% 0%,
    #0680cd 0%,
    var(--medium) 100%
  );
  --active-gradient-focus: radial-gradient(
    50% 75.3% at 50% 0%,
    rgb(6 128 205 / 60%) 0%,
    rgb(0 91 148 / 70%) 100%
  );
  --mobile-anchormenu-boxshadow: 0 4px 43px 4px rgb(0 0 0 / 35%);
  --start-page-content-grid-background: #fafafa;

  /* Navigation */
  --platinum: #e5e4e2;
  --platinum-1: #e5e4e291;
  --white-smoke: #f5f5f5;
  --bright-gray: #e8e9eb;
  --white-gray: #f2f3f5;
}
