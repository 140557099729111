@import "./tools";
@import "./mediaqueries";

h1.display,
h2.display,
h3.display {
  font-family: var(--font-heading-semibold);
  font-weight: normal;
  margin: 0;
  line-height: 93%;

  @include laptop {
    line-height: 95%;
  }
}

h1 {
  &.display {
    font-size: pxToRem(44);

    @include laptop {
      font-size: pxToRem(60);
    }
  }

  &.display--pr {
    font-size: pxToRem(33);

    @include laptop {
      font-size: pxToRem(44);
    }
  }
}

h2.display {
  font-size: pxToRem(33);

  @include laptop {
    font-size: pxToRem(44);
  }

  + p {
    margin-top: pxToRem(33);
  }
}

h3.display {
  font-size: pxToRem(28);

  @include laptop {
    font-size: pxToRem(32);
  }
}

p.display {
  font-size: pxToRem(18);
  margin: pxToRem(13) 0 0 0;
  color: var(--foreground-2);

  &.lead {
    font-family: var(--font-heading-semibold);
    font-size: pxToRem(20);
    line-height: 119.5%;
    color: var(--black);

    @include laptop {
      font-size: pxToRem(30);
    }
  }
}
